import React from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom'
import isScreenVertical from './utils/helpers/isScreenVertical';
import checkStyle from './utils/helpers/checksStyle';
import checkStyleVer from './utils/helpers/checkStyleVer';
const Layout1 = React.lazy(() => import('./layouts/layout/Style1'));
const Layout2 = React.lazy(() => import('./layouts/layout/Style2'));
const VerticalLayout1 = React.lazy(() => import('./layouts/verticalLayout/Style1'));
const VerticalLayout2 = React.lazy(() => import('./layouts/verticalLayout/Style2'));

function App() {
  return (
    <BrowserRouter>
      <LayoutSelector></LayoutSelector>
    </BrowserRouter>
  );
}

const LayoutSelector = ({ children }) => {
  let url = new URL(window.location);
  let search_params = url.searchParams;
  let style;
  if (search_params.has('style')) {
    style = search_params.get('style')
  }
  else {
    style = 1
    search_params.set('style', style)
  }
  // Automatically add location ID (might to remove later)
  if (!search_params.has('locationId')) {
    search_params.set('locationId', 7)
  }

  let myStyle = checkStyle(style)
  const isVer = isScreenVertical()
  let lastStyle = checkStyleVer(myStyle, isVer)

  return (
    <>
      <React.Suspense fallback={<></>}>
        {(lastStyle == 1) && <VerticalLayout1 />}
        {(lastStyle == 2) && <VerticalLayout2 />}
        {(lastStyle == 3) && <Layout1 />}
        {(lastStyle == 4) && <Layout2 />}
      </React.Suspense>
      {children}
    </>
  )
}


export default App;
