import { combineReducers } from "redux"
import assets from "./assets/"
import auth from "./auth/"
import i18n from "./i18n/"

const rootReducer = combineReducers({
  assets: assets,
  auth: auth,
  i18n: i18n,
})

export default rootReducer
