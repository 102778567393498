const initialState = {
  user: null,
  pin: null
};

export default function assetReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_AUTH':
      return {
        ...state,
        user: action.payload.user,
        pin: action.payload.pin
      };
    default:
      return state;
  }
}
