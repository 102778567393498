function checkStyleVer(style, isVer) {
    let lastStyle
    if (isVer) {
        //VERTICAL
        if (style == 1) {
            //VERTICAL ESTILO 1
            lastStyle = 1
        }
        else {
            //VERTICAL ESTILO 2
            lastStyle = 2
        }
    }
    else {
        // HORIZONTAL
        if (style == 1) {
            // HORIZONTAL ESTILO 1
            lastStyle = 3;
        }
        else {
            // HORIZONTAL ESTILO 2
            lastStyle = 4;
        }
    }
    return lastStyle;
}

export default checkStyleVer
