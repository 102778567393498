function checkStyle(style) {
    if (style == 1) {
        style = 1
    }
    else if (style == 2) {
        style = 2
    }
    else {
        style = 1
    }
    return style
}

export default checkStyle
