const initialState = {
  assetList: false,
};

export default function assetReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_ASSETS_LIST':
      return {
        ...state,
        assetList: action.payload
      };
    case 'SET_USER_KPIS':
      return {
        ...state,
        userKpis: action.payload
      };
    case 'GET_ASSET_CATEGORIES_LIST_SUCCESS':
      return {
        ...state,
        assetCategories: action.payload
      };
    default:
      return state;
  }
}
